import React, { useState } from 'react';
import './create-product.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import axios from 'axios';
import Table from 'components/dataTable/Table';
import { useNavigate } from 'react-router-dom';
import { findIndex, first } from 'lodash';

export const CreateProduct = ({ title }) => {
  const addProduct = (body) => {
    return axios.post(`/api/admin/1/products`, body);
  };
  const SIZE = [
    { id: 'Standard', text: 'Standard' },
    { id: 'Small', text: 'Small' },
    { id: 'Medium', text: 'Medium' },
    { id: 'Large', text: 'Large' },
    { id: 'Extra Large', text: 'Extra Large' },
    { id: '16', text: '16' },
    { id: '18', text: '18' },
    { id: '20', text: '20' },
    { id: '22', text: '22' },
    { id: '24', text: '24' },
    { id: '26', text: '26' },
    { id: '28', text: '28' },
    { id: '30', text: '30' },
    { id: '32', text: '32' },
    { id: '34', text: '34' },
    { id: '36', text: '36' },
    { id: '38', text: '38' },
    { id: '40', text: '40' },
    { id: '42', text: '42' },
  ];

  const INITIAL_STATE = {
    code: '',
    category: '',
    name: '',
    price: '',
    selectedSizes: [],
  };

  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_STATE);

  const onHandleChange = (e) => {
    const currentState = { ...state };
    const { name, value } = e.currentTarget;
    currentState[name] = value;
    if (name === 'category') {
      currentState.selectedSizes = [];
    }
    setState(currentState);
  };

  const onSubmit = async (e, move = false) => {
    e.preventDefault();
    const [error] = await addProduct(state);
    if (!error) {
      setState(INITIAL_STATE);
      if (move) {
        navigate('/products');
      }
    }
  };

  const onChangeSizeQuantity = (e, params) => {
    const copiedArrayOfSizes = [...state.selectedSizes];

    let item = copiedArrayOfSizes.filter((item) => item.size === params.id);
    if (item.length) {
      item = first(item);
      item.quantity = e.target.value;
      item.size = params.id;

      const exists = findIndex(copiedArrayOfSizes, (a) => {
        return a.size === params.id;
      });
      copiedArrayOfSizes[exists] = item;
      if (exists) {
        setState((currentState) => ({
          ...currentState,
          selectedSizes: copiedArrayOfSizes,
        }));
      }
    } else {
      setState((currentState) => {
        return {
          ...currentState,
          selectedSizes: [
            ...currentState.selectedSizes,
            {
              size: params.id,
              quantity: e.target.value,
            },
          ],
        };
      });
    }
  };

  const columns = [
    {
      selector: 'text',
      name: 'SIZE',
    },
    {
      selector: 'quantity',
      name: 'Quantity',
      width: 250,
      cell: (params) => {
        return (
          <div>
            <input
              type="number"
              className="sale-price-input"
              onChange={(e) => {
                onChangeSizeQuantity(e, params);
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div className="newuser">
      <div>
        <Sidebar />
      </div>
      <div className="newContainer">
        <div className="contact-container">
          <div className="contact">
            <h1>{title}</h1>
            <h3>Product Information</h3>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Code:</label>
                  <input
                    type="text"
                    className="form-control form-input"
                    name="code"
                    value={state.code}
                    onChange={onHandleChange}
                    placeholder="Product Code"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control form-input"
                    name="name"
                    value={state.name}
                    onChange={onHandleChange}
                    placeholder="Product Name"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Category:</label>
                  <select
                    className="form-control form-input"
                    name="category"
                    onChange={onHandleChange}
                  >
                    <option>Choose...</option>
                    {['Karachi', 'Lahore'].map((item) => (
                      <option key={item} value={item}>
                        {`${item}`}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label>Price:</label>
                  <input
                    type="text"
                    className="form-control form-input"
                    name="price"
                    value={state.price}
                    onChange={onHandleChange}
                    placeholder="Price"
                  />
                </div>
                <div className="form-group col-md-6"></div>
              </div>
            </form>
          </div>
        </div>{' '}
        <div>
          <div>
            <div className="d-flex justify-content-evenly">
              <div>
                <h2>Sizes Quantity</h2>
                <Table pagination={false} columns={columns} data={SIZE} />{' '}
              </div>
              <div></div>
              <div></div>
            </div>
          </div>
          <button
            type="Submit"
            onClick={(e) => {
              onSubmit(e, true);
            }}
          >
            Save
          </button>
          <button type="Submit" onClick={onSubmit}>
            Save & continue
          </button>
          <button
            type="Submit"
            onClick={() => {
              navigate('/products');
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
