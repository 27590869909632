import Sidebar from '../../components/sidebar/Sidebar';
import Widget from '../../components/widget/Widget';
import { useCallback, useEffect, useState } from 'react';
import Table from 'components/dataTable/Table';
import moment from 'moment';
import axios from 'axios';

const Home = () => {
  const [state, setState] = useState({
    orders: [],
    totalCustomers: 0,
    totalOrders: 0,
    totlaEarnings: 0,
    totalProfit: 0,
  });

  const dashboard = () => {
    return axios.get(`/api/admin/1/dashboard`);
  };

  const getOrders = () => {
    return axios.get(`/api/admin/1/orders`);
  };

  const getDashboard = useCallback(async () => {
    const [error, success] = await dashboard();

    const [errorOrder, successOrder] = await getOrders();

    if (!error) {
      setState(success.data);
    }

    if (!errorOrder) {
      setState((currentState) => {
        return {
          ...currentState,
          orders: successOrder.data,
        };
      });
    }
  }, []);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  const columns = [
    {
      selector: 'id',
      name: 'Order Id',
      cell: (params) => {
        return <div>{params.id}</div>;
      },
    },
    {
      selector: 'createdAt',
      name: 'Date',
      cell: (params) => {
        return (
          <div>
            {moment.unix(params.createdAt).format('DD-MM-YYYY hh:mm a')}
          </div>
        );
      },
    },
    {
      selector: 'total_items',
      name: 'Total Items',
      cell: (params) => {
        return <div>{params.total_items}</div>;
      },
    },
    {
      selector: 'total_purchase_amount',
      name: 'Total Purchase Amount',
      cell: (params) => {
        return <div>{params.total_purchase_amount}</div>;
      },
    },
    {
      selector: 'total_sale_amount',
      name: 'Total Sale Amount',
      cell: (params) => {
        return <div>{params.total_sale_amount}</div>;
      },
    },
    {
      selector: 'Total Profit',
      name: 'Total Profit',
      cell: (params) => {
        return (
          <div>{params.total_sale_amount - params.total_purchase_amount}</div>
        );
      },
    },
  ];

  return (
    <div className="home bg-light">
      <div className="side-nav">
        <Sidebar />
      </div>
      <div className="homeContainer">
        <div className="widgets">
          <Widget type="customers" amount={state.totalCustomers} />
          <Widget type="order" amount={state.totalOrders} />
        </div>
        <div className="list-container">
          <div className="listTitle">Latest Transactions</div>
          <Table columns={columns} data={state.orders} />
        </div>
      </div>
    </div>
  );
};

export default Home;
