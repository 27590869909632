import { useCallback, useEffect, useState } from 'react';
import './products/products.scss';
import './pos.scss';
import axios from 'axios';
import Table from '../components/dataTable/Table';
const POS = () => {
  const [state, setState] = useState([]);
  const [total, setTotal] = useState(0);
  const [name, setName] = useState('');

  const createOrder = (body) => {
    return axios.post(`/api/admin/1/orders`, body);
  };
  const getUnsoldProducts = () => {
    return axios.get(`/api/admin/1/products/unsold`);
  };

  const INITIAL_STATE = [];
  const [selectedItems, setSelectedItems] = useState(INITIAL_STATE);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const getAllProducts = useCallback(async () => {
    const [error, success] = await getUnsoldProducts();
    if (!error && success.data.length) {
      setState(success.data);
    }
  }, []);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const columns = [
    {
      selector: 'code',
      name: 'Code',
      cell: (params) => {
        return <div>{params.code}</div>;
      },
      width: 200,
    },
    {
      selector: 'name',
      name: 'Name',
      cell: (params) => {
        return <div>{params.name}</div>;
      },
    },
    {
      selector: 'category',
      name: 'Category',
      cell: (params) => {
        return <div>{params.category}</div>;
      },
    },
    {
      selector: 'purchase_price',
      name: 'Price',
      cell: (params) => {
        return <div>{params.product_items[0].purchase_price}</div>;
      },
    },
    {
      selector: 'quantity',
      name: 'QTY',
    },
    {
      selector: 'available_sizes',
      name: ' Sizes',
      width: 500,
      cell: (params) => {
        const sizes = params.product_items
          .map((item) => item.size)
          .toString()
          .replaceAll(',', ', ');
        return <div>{sizes}</div>;
      },
    },
  ];

  const onHandleSalePrice = (itemId, e) => {
    const items = [...selectedItems];

    setTotal(calculateTotal(items));
    setSelectedItems(items);
  };

  const getSaleQuantity = (itemId) => {
    const items = [...selectedItems];
    let sale_quantity = 1;

    items.forEach((item) => {
      if (item.id === itemId) {
        sale_quantity = item.sale_quantity;
      }
    });
    return sale_quantity;
  };

  const onHandleSaleQuantity = (itemId, e) => {
    const items = [...selectedItems];

    setTotal(calculateTotal(items));
    setSelectedItems(items);
  };

  const getSalePrice = (itemId) => {
    const items = [...selectedItems];
    let salePrice = 0;

    items.forEach((item) => {
      if (item.id === itemId) {
        salePrice = item.sale_price;
      }
    });
    return salePrice;
  };

  const SELECTED_ITEM_COLUMNS = [
    {
      selector: 'id',
      name: 'ID',
      cell: (params) => {
        return <div>{params.id}</div>;
      },
    },
    {
      selector: 'code',
      name: 'Code',
      cell: (params) => {
        return <div>{params.code}</div>;
      },
    },
    {
      selector: 'name',
      name: 'Name',
      cell: (params) => {
        return <div>{params.name}</div>;
      },
    },
    {
      selector: 'category',
      name: 'Category',
      cell: (params) => {
        return <div>{params.category}</div>;
      },
    },
    {
      selector: 'QTY',
      name: 'QTY',
      width: 200,
      cell: (params) => {
        return (
          <div>
            <input
              type="number"
              className="sale-price-input"
              name="quantity"
              onChange={(e) => onHandleSaleQuantity(params.id, e)}
              value={getSaleQuantity(params.id)}
            />
          </div>
        );
      },
    },
    {
      selector: 'sale_price',
      name: 'Sale Price',
      width: 200,
      cell: (params) => {
        return (
          <div>
            <input
              type="text"
              className="sale-price-input"
              onChange={(e) => onHandleSalePrice(params.id, e)}
              value={getSalePrice(params.id)}
            />
          </div>
        );
      },
    },
    {
      selector: 'size',
      name: 'Available Sizes',
      width: 200,
      cell: (params) => {
        return <div>{params.size}</div>;
      },
    },
  ];
  const calculateTotal = (items) => {
    let subTotal = 0;
    items.forEach((item) => {
      subTotal =
        subTotal + Number(item.sale_price) * Number(item.sale_quantity);
    });
    return subTotal;
  };

  const onItemSelect = (items) => {
    const newItems = items.map((item) => {
      if (item && typeof item.sale_price == 'undefined') {
        item.sale_price = item.product_items[0].purchase_price;
        item.sale_quantity = 1;
      }
      return item;
    });

    setTotal(calculateTotal(newItems));
    setSelectedItems(newItems);
  };

  const openReciept = (orderId) => {
    window.open(`/reciept/${orderId}`, '_blank');
  };

  const onHandleSubmit = async () => {
    if (selectedItems.length) {
      const [error, success] = await createOrder({
        totalSaleAmount: total,
        items: selectedItems,
        customerName: name,
      });

      if (!error) {
        setSelectedItems([]);
        setClearSelectedRows(true);
        setTotal(0);
        getAllProducts();
        openReciept(success.data.orderId);
      }
    } else {
      alert('Please select item before odering.');
    }
  };
  return (
    <div className="d-flex-row justify-content-between container">
      <div className="container list-container">
        <Table
          clearSelectedRows={clearSelectedRows}
          onSelectItem={({ selectedRows }) => onItemSelect(selectedRows)}
          selectableRows={true}
          columns={columns}
          data={state}
        />
      </div>
      <div className="container summary-container">
        <h2>Selected Items</h2>
        <Table columns={SELECTED_ITEM_COLUMNS} data={selectedItems} />
        <label>Name:</label>
        <input
          type="text"
          className="form-control form-input"
          value={name}
          placeholder="Customer Name"
          onChange={(e) => setName(e.currentTarget.value)}
        />
        <div>Total: {total}</div>
        <div>
          <button className="btn btn-primary" onClick={onHandleSubmit}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default POS;
