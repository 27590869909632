import React from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

const Table = (props) => {
  const tableData = {
    columns: props.columns,
    data: props.data,
  };

  return (
    <DataTableExtensions
      color="green"
      {...tableData}
      export={props.export}
      print={false}
      filterPlaceholder="Search"
    >
      <DataTable
        title={props.title}
        columns={props.columns}
        data={props.data}
        defaultSortFieldId={1}
        pagination={props.pagination === false ? false : true}
        clearSelectedRows={props.clearSelectedRows}
        selectableRowsNoSelectAll={props.selectableRowsNoSelectAll}
        selectableRows={props.selectableRows}
        onSelectedRowsChange={props.onSelectItem}
        defaultSortOrder={'createdAt'}
        defaultSortAsc={false}
      />
    </DataTableExtensions>
  );
};

export default Table;
