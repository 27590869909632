import { useCallback, useEffect, useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DataTable from '../../components/dataTable/dataTable';
import axios from 'axios';

const DeletedProducts = () => {
  const [state, setState] = useState([]);

  const getDeletedProducts = () => {
    return axios.get(`/api/admin/1/products`, { params: { deleted: true } });
  };

  const getAllProducts = useCallback(async () => {
    const [error, success] = await getDeletedProducts();

    if (!error) {
      setState(success.data);
    }
  }, []);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'code',
      headerName: 'Code',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'category',
      headerName: 'Category',
    },
    {
      field: 'price',
      headerName: 'Price',
      renderCell: (params) => {
        return <div>{params.row.product_items[0].purchase_price}</div>;
      },
    },
    {
      field: 'available_sizes',
      headerName: 'Available Sizes',
      width: 250,
      renderCell: (params) => {
        const sizes = params.row.product_items
          .map((item) => item.size)
          .toString()
          .replaceAll(',', ', ');
        return <div>{sizes}</div>;
      },
    },
  ];

  return (
    <div className="list">
      <div>
        <Sidebar />
      </div>
      <div className="list-container">
        <DataTable
          columns={columns}
          data={state}
          tableTitle="Deleted Products"
          checkboxSelection={false}
        />
      </div>
    </div>
  );
};

export default DeletedProducts;
