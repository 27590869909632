import Sidebar from '../../components/sidebar/Sidebar';
import Widget from '../../components/widget/Widget';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const Setting = () => {
  const [state, setState] = useState({
    orders: [],
    totalCustomers: 0,
    totalOrders: 0,
    totlaEarnings: 0,
    totalProfit: 0,
  });

  const dashboard = () => {
    return axios.get(`/api/admin/1/dashboard`);
  };

  const getOrders = () => {
    return axios.get(`/api/admin/1/orders`);
  };

  const getDashboard = useCallback(async () => {
    const [error, success] = await dashboard();
    const [errorOrder, successOrder] = await getOrders();
    if (!error) {
      setState(success.data);
    }
    if (!errorOrder) {
      setState((currentState) => {
        return {
          ...currentState,
          orders: successOrder.data,
        };
      });
    }
  }, []);
  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  return (
    <div className="home">
      <div>
        <Sidebar />
      </div>
      <div className="homeContainer">
        <div className="widgets">
          <Widget type="earning" amount={state.totlaEarnings} />
          <Widget type="profit" amount={state.totalProfit} />
        </div>
      </div>
    </div>
  );
};

export default Setting;
