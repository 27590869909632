import { Link } from 'react-router-dom';
import './navbar.scss';

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="Wrapper">
        <div className="items">
          <div className="top">
            <Link to="/" style={{ textDecoration: 'none' }}>
              <span className="logo">ADMIN</span>
            </Link>
          </div>
        </div>
      </div>
    
    </div>
  );
};

export default Navbar;
