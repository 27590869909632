import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';
import loaderSlice from './slices/loaderSlice';
import loginSlice from './slices/loginSlice';
import storage from 'redux-persist/lib/storage';

const reducers = combineReducers({
  auth: loginSlice.reducer,
  loader: loaderSlice.reducer,
});
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};
const persistedReducer = persistReducer(persistConfig, reducers);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
export const persistor = persistStore(store);
