import React from 'react';
import animationData from '../assets/lotties/lf20_aidqj9aw.json';
import Lottie from 'react-lottie';

export const Loader = ({ show }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    show && (
      <div
        style={{
          position: 'absolute',
          zIndex: 10000,
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          backgroundColor: '#f3f3f3',
          opacity: 0.9,
        }}
      >
        <Lottie options={defaultOptions} height={200} width={200} />
      </div>
    )
  );
};

export default Loader;
