import './sidebar.scss';
import GridViewIcon from '@mui/icons-material/GridView';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { NavLink } from 'react-router-dom';
import "./Style.css"
const Sidebar = () => {
  return (
    <div className="sidenav" id="sidenav">
      <hr />
      <div className="center">
        <ul className="pt-5">
        
            <li>
            <NavLink className='d-flex' to="/" style={{ textDecoration: 'none' }} >
              <GridViewIcon className="icon" id="li" />
              <div>
                <span >Dashboard</span>
              </div>
          </NavLink>

            </li>
            <li>
          <NavLink to="/products" className="d-flex" style={{ textDecoration: 'none' }}>
           
              <StorefrontOutlinedIcon className="icon" id="product" />
              <span>Products</span>
           
          </NavLink>
          </li>
          <li>
          <NavLink to="/deletedProducts" className="d-flex" style={{ textDecoration: 'none' }}>
            
              <DeleteOutlineOutlinedIcon className="icon" />
              <div>
                <span className="span-ic">
                  <span>Deleted</span> <span>Products</span>
                </span>
              </div>
           
          </NavLink>
          </li>
          <li>
          <NavLink to="/orders" className="d-flex" style={{ textDecoration: 'none' }}>
              <CreditCardIcon className="icon" />
              <span >Orders</span>
          </NavLink>
          </li>
          <li>
          <NavLink to="/pos" className="d-flex" target="_blank" style={{ textDecoration: 'none' }}>
         
              <AddBusinessOutlinedIcon className="icon" />
              <span>POS</span>
           
          </NavLink>
          </li>
          <li>
          <NavLink to="/expenses" className="d-flex" style={{ textDecoration: 'none' }}>
          
              <AttachMoneyIcon className="icon" />
              <span>Expense</span>
           
          </NavLink>
          </li>
          <li>
          <NavLink className="d-flex" to="/setting" style={{ textDecoration: 'none' }}>
           
              <SettingsOutlinedIcon className="icon " />
              <span>Settings</span>
           
          </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
