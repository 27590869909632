import './data-table.scss';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

const UserDatatable = ({
  columns,
  data,
  tableTitle,
  addNewButtonLink,
  checkboxSelection,
}) => {
  return (
    <div className="datatable">
      {tableTitle && (
        <div className="datatableTitle">
          {tableTitle}
          {addNewButtonLink && (
            <Link to={addNewButtonLink} className="link">
              Add New
            </Link>
          )}
        </div>
      )}
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection={checkboxSelection}
      />
    </div>
  );
};

export default UserDatatable;
