import './login.scss';
import Logo from '../../assets/images/Logo.png';
import React from 'react';
import { useDispatch } from 'react-redux';
import { login } from 'store/slices/loginSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from 'schemas';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
  const adminLogin = (body) => {
    return axios.post(`/api/admin/login`, body);
  };

  const {
    formState: { errors },
    control,
    register,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = async (body) => {
    const [error, success] = await adminLogin(body);
    // handle the rest. Error OR Success
    if (success) {
      const { data } = success;
      dispatch(login(data));
      navigate('/');
    } else if (error) {
      console.log(error);
    }
  };
  return (
    <div className="login">
      <form onSubmit={handleSubmit(onSubmit)}>
        <img src={Logo} alt="" />
        <div className="login-title">Merchant Login </div>
        <input
          type="text"
          control={control}
          errors={errors}
          placeholder="Username"
          {...register('username')}
        />
        <input
          type="password"
          errors={errors}
          {...register('password')}
          control={control}
          placeholder="Password"
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default Login;
