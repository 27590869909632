import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Logo from '../assets/images/Logo.png';
import moment from 'moment';
import axios from 'axios';
import './reciept.css';

export const Reciept = () => {
  const { orderId } = useParams();
  const [state, setState] = useState([]);
  const getOrder = (orderId) => {
    return axios.get(`/api/admin/1/orders/${orderId}`);
  };
  const getAllProducts = useCallback(async () => {
    const [error, success] = await getOrder(orderId);

    if (!error) {
      setState(success.data);
    }
  }, [orderId]);
  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const SIZES = {
    Standard: 'St',
    Small: 'Sm',
    Medium: 'M',
    Large: 'L',
    'Extra Large': 'XL',
  };
  const fullColSpan = 6;
  return (
    <center>
      <table>
        <tr>
          <th colSpan={fullColSpan}>
            <center>
              <h6>Purchase slip</h6>
              <img src={Logo} alt="" />
              <h2>میاں مارکیٹ نمبر ا دکان نمبر 1 مسلم مینہ بازار پشاور</h2>
              <h4>Contact : 03459100918 - 03239572866</h4>
              <h4>WhatsApp : 03459100918</h4>
            </center>
          </th>
        </tr>

        <h4> Customer: {state.customer_name}</h4>
        <tr>
          <th>Order#:{state.id}</th>

          <th>Date:</th>

          <th colSpan="3" className="spacing">
            {moment.unix(state.createdAt).format('DD-MM-YYYY hh:mm a')}
          </th>
        </tr>
        <tr>
          <th className="border">Code</th>
          <th className="border">Name </th>
          <th className="border">QTY</th>
          <th className="border">Size</th>
          <th className="border">Price</th>
        </tr>
        {state &&
          state.order_items &&
          state.order_items.map((item) => {
            const productCode = item.product.code;
            const sizes = item.product.product_items
              .map((product) =>
                SIZES[product.size] ? SIZES[product.size] : product.size
              )
              .toString()
              .replaceAll(',', ', ');
            return (
              <tr key={state.order_items.id}>
                <td>{productCode}</td>
                <td>{item.product.name}</td>
                <td>{item.quantity}</td>
                <td>{sizes}</td>
                <td>{item.sale_price}/-</td>
              </tr>
            );
          })}
        <tr>
          <td className="border_dash">
            <strong>Total :</strong>
          </td>
          <td className="border_dash"></td>
          <td className="border_dash">{state.total_quantity}</td>
          <td className="border_dash "></td>
          <td className="border_dash ">{state.total_sale_amount}/-</td>
        </tr>
        <tr>
          <td colSpan={fullColSpan}>
            <h2>
              <center>شرائط و ضوابط</center>
              <div className="terms-and-condition" lang="ur">
                خریدا ہوا مال ہر گز واپس نہیں ہوگا۔
              </div>
              <div className="terms-and-condition" lang="ur">
                رسید کے بغیر خریدا ہوا مال تبدیل نہیں ہو گا
              </div>
              <div className="terms-and-condition" lang="ur">
                البتہ تبدیل ایک دن کے اندر ہی ہوگا۔
              </div>
            </h2>
          </td>
        </tr>
        <tr>
          <td colSpan={fullColSpan}>
            <h4>
              <center>Thank You for Shopping</center>
            </h4>
          </td>
        </tr>
      </table>
    </center>
  );
};
