import axios from 'axios';
import { showLoader } from 'store/slices/loaderSlice';
import successHandler from 'services/config/successHandler';
import errorHandler from 'services/config/errorHandler';

axios.defaults.timeout = 20000;
axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const configureNetworkRouter = (store) => {
  const delay = (duration) => {
    return new Promise((resolve) => setTimeout(resolve, duration));
  };

  axios.interceptors.request.use(async (config) => {
    store.dispatch(showLoader());
    const { accessToken, isAuthenticated } = store.getState().auth;
    if (isAuthenticated) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    const delayInMs = 250;
    await delay(delayInMs);
    return config;
  });

  axios.interceptors.response.use(successHandler, errorHandler);
};

export default configureNetworkRouter;
