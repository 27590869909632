import { useCallback, useEffect, useState } from 'react';
import '../products/products.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import DataTable from '../../components/dataTable/dataTable';
import axios from 'axios';

const ExpenseList = () => {
  const [state, setState] = useState([]);

  const getExpense = (body) => {
    return axios.get(`/api/admin/1/expenses`);
  };

  const getAllExpenses = useCallback(async () => {
    const [error, success] = await getExpense();
    if (!error && success.data && success.data.length) {
      setState(success.data);
    }
  }, []);

  useEffect(() => {
    getAllExpenses();
  }, [getAllExpenses]);

  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
    },
    {
      field: 'price',
      headerName: 'Price',
    },
  ];

  return (
    <div className="list">
      <div>
        <Sidebar />
      </div>

      <div className="list-container">
        <DataTable
          columns={columns}
          data={state}
          tableTitle="Expenses"
          addNewButtonLink="/expenses/add"
          checkboxSelection={false}
        />
      </div>
    </div>
  );
};
export default ExpenseList;
