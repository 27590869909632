import React, { useState } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CreateExpense = () => {
  const createExpense = (body) => {
    return axios.post(`/api/admin/1/expenses`, body);
  };
  const INITIAL_STATE = {
    name: '',
    price: '',
    description: '',
  };
  const navigate = useNavigate();
  const [state, setState] = useState(INITIAL_STATE);

  const onHandleChange = (e) => {
    const currentState = { ...state };
    const { name, value } = e.currentTarget;
    currentState[name] = value;
    setState(currentState);
  };

  const onSubmit = async (e, move = false) => {
    e.preventDefault();
    try {
      const [error] = await createExpense(state);
      if (!error) {
        setState(INITIAL_STATE);
        if (move) {
          navigate('/products');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="newuser">
      <div>
        <Sidebar />
      </div>
      <div className="newContainer">
        <div className="contact-container">
          <div className="contact">
            <h3>Expense</h3>
            <form>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Name:</label>
                  <input
                    type="text"
                    className="form-control form-input"
                    name="name"
                    value={state.name}
                    onChange={onHandleChange}
                    placeholder=" Name"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Description:</label>
                  <input
                    className="form-control form-input"
                    name="description"
                    type="text"
                    value={state.description}
                    onChange={onHandleChange}
                    placeholder="Description"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label>Price:</label>
                  <input
                    type="text"
                    className="form-control form-input"
                    name="price"
                    value={state.price}
                    onChange={onHandleChange}
                    placeholder="Price"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div>
          <button type="Submit" onClick={onSubmit}>
            Save & continue
          </button>
          <button
            type="Submit"
            onClick={() => {
              navigate('/');
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateExpense;
