import { store } from '../../store';
import { isNil } from 'lodash';
import { hideLoader } from 'store/slices/loaderSlice';
// import { Toast } from 'components/common/Toast';
import { logout } from 'store/slices/loginSlice';
const DEFAULT_ERROR_MESSAGE = 'Something went wrong!';
const REVOKE_ACCESS_CODE = 409;

const errorResponseHandler = (error) => {
  try {
    if (error.response) {
      if (error.response.status === REVOKE_ACCESS_CODE) {
        store.dispatch(logout());
      }
      // eslint-disable-next-line no-unused-expressions
      isNil(error.response.data)
        ? DEFAULT_ERROR_MESSAGE
        : error.response.data.message ||
          (typeof error.response.data === 'string' && error.response.data) ||
          DEFAULT_ERROR_MESSAGE;
    } else {
      return error.data.message;
    }
    store.dispatch(hideLoader());
    // Toast.error(error.response.data.message || error.message)
    return [error, null];
  } catch (error) {
    console.error(error);
  }
};

export default errorResponseHandler;
