import Navbar from 'components/navbar/Navbar';
import Sidebar from 'components/sidebar/Sidebar';
import './home/home.scss';
import { useCallback, useEffect, useState } from 'react';
import Table from 'components/dataTable/Table';
import moment from 'moment';
import { Print } from '@mui/icons-material';
import axios from 'axios';

const Orders = () => {
  const [state, setState] = useState([]);

  const getOrders = () => {
    return axios.get(`/api/admin/1/orders`);
  };

  const getAllOrders = useCallback(async () => {
    const [errorOrder, successOrder] = await getOrders();
    if (!errorOrder) {
      setState(successOrder.data);
    }
  }, []);

  useEffect(() => {
    getAllOrders();
  }, [getAllOrders]);

  const columns = [
    {
      selector: 'id',
      name: 'Order Id',
      sortable: true,
      cell: (params) => {
        return <div>{params.id}</div>;
      },
    },
    {
      selector: 'createdAt',
      name: 'Date',
      sortable: true,
      cell: (params) => {
        return (
          <div>
            {moment.unix(params.createdAt).format('DD-MM-YYYY hh:mm a')}
          </div>
        );
      },
    },
    {
      selector: 'total_quantity',
      name: 'Total Items',
      sortable: true,
      cell: (params) => {
        return <div>{params.total_quantity}</div>;
      },
    },
    {
      selector: 'total_purchase_amount',
      name: 'Total Purchase Amount',
      sortable: true,
      cell: (params) => {
        return <div>{params.total_purchase_amount}</div>;
      },
    },
    {
      selector: 'total_sale_amount',
      name: 'Total Sale Amount',
      sortable: true,
      cell: (params) => {
        return <div>{params.total_sale_amount}</div>;
      },
    },
    {
      selector: 'Total Profit',
      name: 'Total Profit',
      sortable: true,
      cell: (params) => {
        return (
          <div>{params.total_sale_amount - params.total_purchase_amount}</div>
        );
      },
    },
    {
      selector: 'Print receipt',
      name: 'Print receipt',
      sortable: true,
      cell: (params) => {
        return (
          <Print
            onClick={() => window.open(`/reciept/${params.id}`, '_blank')}
          />
        );
      },
    },
  ];

  return (
    <div className="home">
      <div>
        <Sidebar />
      </div>
      <div className="homeContainer">
        <div className="list-container">
          <div className="listTitle">Latest Transactions</div>
          <Table export columns={columns} data={state} />
        </div>
      </div>
    </div>
  );
};

export default Orders;
