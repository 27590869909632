import Home from './pages/home/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/login/Login';
import { CreateProduct } from 'pages/product';
import ProductList from 'pages/products/Products';
import POS from 'pages/POS';
import Orders from 'pages/Orders';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Reciept } from 'components/Reciept';
import Loader from 'components/Loader';
import CreateExpenes from 'pages/Expenes/CreateExpenes';
import DeletedProducts from 'pages/Deleted-products/DeletedProducts';
import Setting from 'pages/Setting/Setting';
import ExpenseList from 'pages/Expenes/Expenses';
import { UpdateProduct } from 'pages/product/UpdateProduct';
import Navbar from 'components/navbar/Navbar';
import './App.css';

function App() {
  const state = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.loader);

  useEffect(() => {
    if (!state.isAuthenticated && window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  }, [state.isAuthenticated]);

  return (
    <BrowserRouter>
    <div>
    <Navbar />

    </div>

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Loader show={loading} />
                <Home />
              </>
            }
          />
          <Route path="products">
            <Route
              index
              element={
                <>
                  <Loader show={loading} />
                  <ProductList />
                </>
              }
            />
            <Route
              path="new"
              element={
                <>
                  <Loader show={loading} />
                  <CreateProduct title="Add New Product" />
                </>
              }
            />
          </Route>
          <Route
            path="products/:id/edit"
            element={
              <>
                <Loader show={loading} />
                <UpdateProduct title="Update Product" />
              </>
            }
          />
          <Route
            path="deletedProducts"
            index
            element={
              <>
                <Loader show={loading} />
                <DeletedProducts />
              </>
            }
          />
          <Route
            path="orders"
            element={
              <>
                <Loader show={loading} />
                <Orders />
              </>
            }
          />
          <Route
            path="reciept/:orderId"
            element={
              <>
                <Loader show={loading} />
                <Reciept />
              </>
            }
          />
          <Route
            path="pos"
            element={
              <>
                <Loader show={loading} />
                <POS />
              </>
            }
          />
          <Route
            path="expenses"
            element={
              <>
                <Loader show={loading} />
                <ExpenseList />
              </>
            }
          />
          <Route
            path="expenses/add"
            element={
              <>
                <Loader show={loading} />
                <CreateExpenes />
              </>
            }
          />
          <Route
            path="setting"
            element={
              <>
                <Loader show={loading} />
                <Setting />
              </>
            }
          />
          <Route
            index
            path="login"
            element={
              <>
                <Loader show={loading} />
                <Login />
              </>
            }
          />
        </Routes>

    </BrowserRouter>
  );
}

export default App;
