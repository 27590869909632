import { hideLoader } from 'store/slices/loaderSlice';
import { store } from '../../store';

const successResponseHandler = (response) => {
  let data = response;
  store.dispatch(hideLoader());
  return [null, data];
};

export default successResponseHandler;
