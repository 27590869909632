import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    showLoader: () => true,
    hideLoader: () => false,
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;
export default loaderSlice;
