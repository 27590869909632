import { useCallback, useEffect, useState } from 'react';
import './products.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DataTable from '../../components/dataTable/dataTable';
import { IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
const ProductList = () => {
  const [state, setState] = useState([]);

  const deletedProduct = (productId) => {
    return axios.delete(`/api/admin/1/products/${productId}`);
  };
  const getProducts = () => {
    return axios.get(`/api/admin/1/products`);
  };

  const navigate = useNavigate();
  const getAllProducts = useCallback(async () => {
    const [error, success] = await getProducts();
    if (!error && success.data && success.data.length) {
      setState(success.data);
    }
  }, []);

  useEffect(() => {
    getAllProducts();
  }, [getAllProducts]);

  const onHandleDelete = async (params) => {
    const [error, success] = await deletedProduct(params.row.id);
    if (!error) {
      getAllProducts();
    } else {
      alert(error);
    }
  };
console.log(state)
  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'code',
      headerName: 'Code',
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'category',
      headerName: 'Category',
    },
    {
      field: 'price',
      headerName: 'Price',
      renderCell: (params) => {
        return <div>{params.row.product_items[0].purchase_price}</div>;
      },
    },
    {
      field: 'available_sizes',
      headerName: ' Sizes',
      width: 200,
      renderCell: (params) => {
        const sizes = params.row.product_items
          .map((item) => item.size)
          .toString()
          .replaceAll(',', ', ');
        return <div>{sizes}</div>;
      },
    },
    {
      field: 'quantity',
      headerName: 'QTY',
    },
    {
      field: 'edit',
      headerName: '',
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            href=""
            aria-label="edit"
            color="primary"
            onClick={() => navigate(`/products/${params.row.id}/edit`)}
          >
            <EditOutlinedIcon className="icons" />
          </IconButton>
        );
      },
    },
    {
      field: 'delete',
      headerName: '',
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => onHandleDelete(params)}
          >
            <DeleteOutlinedIcon className="icons" />
          </IconButton>
        );
      },
    },
  ];
  return (
    <div className="list">
      <div>
        <Sidebar />
      </div>
      <div className="list-container">
        <DataTable
          columns={columns}
          data={state}
          tableTitle="Products"
          addNewButtonLink="/products/new"
          checkboxSelection={false}
        />
      </div>
      
    </div>
  );
};
export default ProductList;
